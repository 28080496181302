.custom-react-toggle .react-toggle-track {
    background-color: #F67BA0;
    width: 56px;
    height: 30px;
    border-radius: 45px;
  }

  .custom-react-toggle.react-toggle--focus .react-toggle-track {
    background-color: #F67BA0;
  }


  .custom-react-toggle.react-toggle--checked .react-toggle-track {
    background-color: #01D9CB;
  }

  .custom-react-toggle.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #F67BA0;
  }

  .custom-react-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #01D9CB;
  }

  .custom-react-toggle .react-toggle-thumb {
    border: none;
    width: 28px;
    height: 28px;
  }

  .custom-react-toggle.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .custom-react-toggle.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
