.CalendarMonth {
  table {
    width: auto;
  }
  &_caption {
    padding-bottom: 50px;
    strong {
      color: #364344;
      font-family: "proxima-soft";
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.DayPicker {
  border: 0;
  box-shadow: none;
  &_weekHeader {
    ul {
    	border-bottom: 1px solid #30C6E2;
      color: #364344;
      font-family: "proxima-soft";
      margin: 0;
      padding: 8px 0;
    }
    small {
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  &Navigation {
    &_button {
      position: absolute;
      top: 18px;
      line-height: .78;
      border-radius: 3px;
      padding: 6px 9px;
      &:nth-child(1) {
        left: 22px;
      }
      &:nth-child(2) {
        right: 22px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}


.CalendarDay {
  border: 0;
  color: #364344;
  font-family: "proxima-soft";
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;

  &:hover {
    border: 0;
  }

  @gradient: linear-gradient(32.13deg, #249CB5 0%, #30C6E2 60.84%, #54ABBA 100%);

  &__selected_end, &__selected_start {
      background: @gradient;
      border-radius: 40px;
      color: white;
      font-family: "proxima-soft";
      &:hover {
        background: @gradient;
      }
  }
  &__selected_span {
    background: white;
    color: #364344;
  }

  &__blocked_out_of_range {
    background-color: transparent;
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      color: #364344;
    }
  }
}

.react-calendar {
  @standard-colour: #364344;
  @active-colour: white;
  @active-background: linear-gradient(32.13deg, #249CB5 0%, #30C6E2 60.84%, #54ABBA 100%);
  @active-tile: {
    border-radius: 50%;
    background: @active-background;
    color: @active-colour;
    font-family: "proxima-soft";
  }
  .heading(@size) {
    background: none;
    color: @standard-colour;
    font-size: @size;
    font-family: "proxima-soft";
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
  }

  width: 273px;
  border: none;
  padding-bottom: 25px;

  &__navigation {
    margin: 12px 0 6px 0;

    &__arrow svg {
      width: 20px;
      height: 20px;
    }

    &__label {
      .heading(18px);
    }

    button {
      min-width: 0;

      &:enabled:hover, &:enabled:focus, &:disabled {
        background: none;
      }
    }
  }

  &__month-view__weekdays abbr[title] {
    .heading(14px);
  }

  &__month-view__weekdays {
    border-bottom: solid 1px #30C6E2;
    margin-bottom: 3px;
  }

  &__month-view__days__day--weekend {
    color: @standard-colour;
  }

  &__tile {
    color: @standard-colour;
    font-family: "proxima-soft";
    font-size: 14px;
    font-weight: 300;

    height: 39px;
    padding: 0;

    &:disabled {
      opacity: 0.4;
      background: none;
      cursor: not-allowed;
    }

    &--now {
      background: none;

      &:enabled:hover, &:enabled:focus {
        background-color: #e4e7e7;
      }
    }

    &--active {
      background: none;

      &:enabled:hover, &:enabled:focus {
        background-color: #33dacd;
        color: @active-colour;
      }
    }

    &--rangeStart, &--rangeEnd {
      @active-tile();

      &:enabled:hover, &:enabled:focus {
        background: @active-background;
        color: @active-colour;
      }
    }

    &--hasActive {
      @active-tile();

      &:enabled:hover, &:enabled:focus {
        background: @active-background;
      }
    }
  }
}
