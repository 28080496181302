html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: proxima-soft, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100% !important;
  width: 100%;
}

input,
textarea,
select,
button {
  font-family: proxima-soft, sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-ms-clear {
  display: none;
}

::-ms-reveal {
  display: none;
}
